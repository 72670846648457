var pendingReports = {
    
    reportsInterval: '',

    startCheckingReports: function() {
        if (this.reportsInterval !== '') {
            return;
        }
        this.reportsInterval = window.setInterval(this.checkPending, 5000);
        //console.log("START CHECK");
    },

    stopCheckingReports: function() {
        clearInterval(this.reportsInterval);
        this.reportsInterval = '';
        //console.log("STOP CHECK");
    },

    checkPending: function() {
        //console.log("MAKE CHECK");
        $.ajax({
            url: '/reports/checkPending/',
            dataType: 'json',
            success: function (response) {
                if (response.matches > 0) {
                    var counter = $('#downloads-counter').html();

                    if (counter < response.generated) {
                        $('#downloads-counter').html(response.generated);
                        alertify.alert("Your queued report is <a href='#' class='trigger-download-modal'>available for download</a>");
                        pendingReports.stopCheckingReports();
                    }
                }
            },
            error: function (response) {
                console.log("/reports/checkPending:ERROR" + response);
            }
        });
    },

};